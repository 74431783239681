import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import {
  laptopSize,
  desktopSize,
  mobileSize,
  salDuration,
  salTimingFunction,
} from '../../../util/variables';
import theme from '../../../util/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AnimatedLink, AnimatedDiv } from '../../ui';
import logoImg from '../../../content/img/foreign/logo_cierre.png';
import AOS from 'aos';
import { useEffect } from 'react';
import './bootstrap.css';
import 'aos/dist/aos.css';
import './styles.css';
import './sal.css';
require(typeof window !== 'undefined' ? './bootstrap.js' : '../../../util/theme');

const ForeignSegmentPortfolio: React.FC = () => {
  useEffect(() => AOS.init({ easing: 'ease-in-out', duration: 500, delay: 0 }), []);

  return (
    <div className="partners">
      <section className="header-meet">
        <div className="container">
          <div className="row content-meet">
            <div className="col">
              <div className="advanced">The Advancement Initiative</div>
            </div>
            <div className="col partners-logo">
              <img src="../../img/base10_partners.svg" />
            </div>
            <div className="col right icon-uni">
              <img src="../../img/icon_uni.svg" />
            </div>
          </div>
        </div>
      </section>
      <section className="header-partners light">
        <div className="container">
          <div className="row intro-partners">
            <div className="col">
              <h1>Meet our portfolio</h1>
            </div>
            <div className="col">
              <div className="graph-description">
                The Base10 Advancement Initiative invests in late stage,
                <span className="light-font">
                  {' '}
                  high growth technology companies to help grow the endowments of Historically Black
                  Colleges & Universities and further diversity, equity, and inclusion across the
                  tech industry.
                </span>
              </div>
            </div>
            <div className="col right hide-mobile">
              <a className="back-link" href="/advancement-initiative/">
                Back
              </a>
            </div>
          </div>
          <div className="row logos-partners">
            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button attentive"
                data-bs-toggle="modal"
                data-bs-target="#attentive"
              >
                <img src="../../img/partners/com_attentive.png" />
              </button>

              <div
                className="modal fade"
                id="attentive"
                tabindex="-1"
                aria-labelledby="attentiveLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="attentiveLabel">
                        <img src="../../img/partners/com_attentive.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Attentive is a personalized text messaging platform that uses real-time
                          behavioral data to drive revenue for DTC brands.
                        </strong>
                      </p>
                      <p>
                        Attentive automatically sends engaging text messages to each subscriber at
                        every step of the customer lifecycle. Over 2,000 leading businesses rely on
                        Attentive.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="250"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              {/* Nubank Trigger */}
              <button
                className="partner-button nubank"
                data-bs-toggle="modal"
                data-bs-target="#nubank"
              >
                <img src="../../img/partners/com_nubank.png" />
              </button>
              {/* Nubank */}
              <div
                className="modal fade"
                id="nubank"
                tabindex="-1"
                aria-labelledby="nubankLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="nubankLabel">
                        <img src="../../img/partners/com_nubank.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Nubank is the largest independent digital bank in the world with more than
                          35 million customers in all Brazilian cities.
                        </strong>
                      </p>
                      <p>
                        The company’s core product offering includes a no-fee credit card with 21
                        million cardholders and a digital savings account, held by about 32.5
                        million people.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              {/* Brex Trigger */}
              <button className="partner-button brex" data-bs-toggle="modal" data-bs-target="#brex">
                <img src="../../img/partners/com_brex.png" />
              </button>
              {/* Brex */}
              <div
                className="modal fade"
                id="brex"
                tabindex="-1"
                aria-labelledby="brexLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="brexLabel">
                        <img src="../../img/partners/com_brex.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          With an innovative approach to corporate credit cards and cash management,
                          Brex launched the first corporate card and rewards program specifically
                          designed for startups.
                        </strong>
                      </p>
                      <p>
                        By rebuilding the credit card tech stack from the ground up, Brex is able to
                        reimagine every aspect of corporate cards, including underwriting,
                        transparency, and approvals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="350"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              {/* Plaid Trigger */}
              <button
                className="partner-button plaid"
                data-bs-toggle="modal"
                data-bs-target="#plaid"
              >
                <img src="../../img/partners/com_plaid.png" />
              </button>
              {/* Plaid */}
              <div
                className="modal fade"
                id="plaid"
                tabindex="-1"
                aria-labelledby="plaidLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="plaidLabel">
                        <img src="../../img/partners/com_plaid.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Plaid is a data network that powers the fintech tools millions of people
                          rely on to live healthier financial lives.
                        </strong>
                      </p>
                      <p>
                        Plaid works with thousands of fintech companies and large banks to make it
                        easy for people to connect their financial accounts to the apps and services
                        they want to use.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="400"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              {/* Aurora Solar Trigger */}
              <button
                className="partner-button aurora"
                data-bs-toggle="modal"
                data-bs-target="#aurora"
              >
                <img src="../../img/partners/com_aurora.png" />
              </button>
              {/* Aurora Solar */}
              <div
                className="modal fade"
                id="aurora"
                tabindex="-1"
                aria-labelledby="auroraLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="auroraLabel">
                        <img src="../../img/partners/com_aurora.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Aurora Solar makes the software that powers the solar industry.
                        </strong>
                      </p>
                      <p>
                        At Aurora Solar, we’re on a mission to create a future of solar energy for
                        all. We propel the industry’s growth by providing software that allows solar
                        professionals–from the nation’s largest solar companies to the thousands of
                        small, local solar installers around the world–to remotely design and sell
                        solar. To date, over 5 million solar projects have been designed in Aurora.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="450"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              {/* KeepTruckin Trigger */}
              <button
                className="partner-button keeptruckin"
                data-bs-toggle="modal"
                data-bs-target="#keeptruckin"
              >
                <img src="../../img/partners/com_keeptruckin.png" />
              </button>
              {/* KeepTruckin */}
              <div
                className="modal fade"
                id="keeptruckin"
                tabindex="-1"
                aria-labelledby="keeptruckinLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="keeptruckinLabel">
                        <img src="../../img/partners/com_keeptruckin.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          KeepTruckin is the leader in fleet management technology. KeepTruckin’s
                          modern hardware and cloud-based software help companies improve the safety
                          and efficiency of their operations.
                        </strong>
                      </p>
                      <p>
                        KeepTruckin provides an integrated fleet management system that scales to
                        meet the needs of small trucking companies to Fortune 500 enterprises.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="500"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              {/* Wealthsimple Trigger */}
              <button
                className="partner-button wealthsimple"
                data-bs-toggle="modal"
                data-bs-target="#wealthsimple"
              >
                <img src="../../img/partners/com_wealthsimple.png" />
              </button>
              {/* Wealthsimple Foundation */}
              <div
                className="modal fade"
                id="wealthsimple"
                tabindex="-1"
                aria-labelledby="wealthsimpleLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="wealthsimpleLabel">
                        <img src="../../img/partners/com_wealthsimple.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Wealthsimple is a financial company on a mission to help everyone achieve
                          financial freedom by providing products and advice that are accessible and
                          affordable.
                        </strong>
                      </p>
                      <p>
                        Using smart technology, Wealthsimple takes financial services that are often
                        confusing, opaque and expensive and makes them simple, transparent, and
                        low-cost.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="550"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              {/* CircleCI Trigger */}
              <button
                className="partner-button circle"
                data-bs-toggle="modal"
                data-bs-target="#circle"
              >
                <img src="../../img/partners/com_circle.png" />
              </button>
              {/* Wealthsimple Foundation */}
              <div
                className="modal fade"
                id="circle"
                tabindex="-1"
                aria-labelledby="circleLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="circleLabel">
                        <img src="../../img/partners/com_circle.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          CircleCI is the leading continuous integration and delivery platform for
                          software innovation at scale.
                        </strong>
                      </p>
                      <p>
                        With intelligent automation and delivery tools, CircleCI is used by the
                        world's best engineering teams to radically reduce the time from idea to
                        execution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="quotes-partners dark">
        <div className="container">
          <div className="row">
            <div className="col-4 hide-mobile"></div>
            <div className="col-8 right">
              <h1
                data-sal="slide-left"
                data-sal-delay="200"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                Big Tech is already moving on diversity
              </h1>
              <div
                className="mid-title"
                data-sal="slide-left"
                data-sal-delay="300"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <h2>
                  Companies taking an investment from the Advancement Initiative can do more for
                  diversity and will have a leg up on D&I within their own organizations
                </h2>
              </div>
            </div>
          </div>
          <div className="row quotes-block">
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="100"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                Since Nubank’s founding, we knew that building strong and diverse teams was
                fundamental to fulfill our mission to revolutionize the financial system in Latin
                America. Welcoming Base10 Partners in our group of investors is another step in this
                direction and recognition of our social and economic commitment.
              </div>
              <div className="quote-meet-info right david">
                <div className="name-quote">David Vélez</div>
                <div className="position-quote">
                  Co-founder and CEO
                  <br />
                  Nubank
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile"></div>
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                We were blown away by Base10’s innovative, unique and generous approach to advancing
                diversity in tech. Aurora couldn’t be more proud to be an Advancement Initiative
                portfolio company, and to thereby help create opportunities for talented students
                who will become the next generation of tech company leaders.
              </div>
              <div className="quote-meet-info right adeyemo">
                <div className="name-quote">Sam Adeyemo</div>
                <div className="position-quote">
                  Co-founder
                  <br />
                  Aurora Solar
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile"></div>
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                Base10’s Advancement Initiative is unlike anything we've seen from other investors,
                and a big reason why we decided to bring them in as a partner to help us build upon
                our diversity and inclusion efforts as we scale. As a company, we're committed to
                echoing the values of our team and investing in creating a diverse and equitable
                workplace.
              </div>
              <div className="quote-meet-info right brian">
                <div className="name-quote">Brian Long</div>
                <div className="position-quote">
                  Co-founder and CEO
                  <br />
                  Attentive
                </div>
              </div>
            </div>
          </div>

          <div className="row quotes-second">
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                Part of our mission at Brex is to reimagine existing systems that are not meeting
                their full potential. With talented students being left behind, it’s clear that the
                system is not supporting URM students as it should be. Brex is joining the
                Advancement Initiative to be part of the change that will build a system where
                everyone has a chance to reach their full potential.
              </div>
              <div className="quote-meet-info right henrique">
                <div className="name-quote">Henrique Dubugras</div>
                <div className="position-quote">
                  Co-CEO
                  <br />
                  Brex
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile"></div>
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                The Advancement Initiative creates a clear path for us to contribute to the success
                of underrepresented minority students who will go on to be the leaders and
                innovators of tomorrow, and we couldn’t be more excited to be participating.
              </div>
              <div className="quote-meet-info right zach">
                <div className="name-quote">Zach Perret</div>
                <div className="position-quote">
                  Co-founder and CEO
                  <br />
                  Plaid
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile"></div>
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="400"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                Participating in the Advancement Initiative was a no-brainer for KeepTruckin. Base10
                has put together the optimal team and optimal structure to make for very necessary
                and very successful social change, and we are thrilled to be on board.
              </div>
              <div className="quote-meet-info right shoaib">
                <div className="name-quote">Shoaib Makani</div>
                <div className="position-quote">
                  Co-founder and CEO
                  <br />
                  KeepTruckin
                </div>
              </div>
            </div>
          </div>

          <div className="row quotes-second">
            <div className="col-1 hide-mobile"></div>
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                Accountability is a driving component of Wealthsimple’s diversity & inclusion
                initiatives, and the Advancement Initiative is something we are proud to share with
                our stakeholders that want us to do more and want us to do better.
              </div>
              <div className="quote-meet-info right michael">
                <div className="name-quote">Michael Katchen</div>
                <div className="position-quote">
                  Co-founder and CEO
                  <br />
                  Wealthsimple
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile"></div>
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                CircleCI aims to partner with organizations that are focused on improving
                representation of marginalized groups in technology. The Advancement Initiative is
                incredibly unique in its approach to solving this issue and we are excited to be
                joining them on this shared mission.
              </div>
              <div className="quote-meet-info right jim">
                <div className="name-quote">Jim Rose</div>
                <div className="position-quote">
                  CEO
                  <br />
                  CircleCI
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile"></div>
          </div>

          <div
            className="row portfolio-information"
            data-sal="fade"
            data-sal-delay="100"
            data-sal-duration="700"
            data-sal-easing="easeInOutQuad"
          >
            <div className="col white-bg for-hbcu">
              <div className="info-title">FOR HBCUs</div>
              <ul className="list">
                <li>Quick growth for endowments to keep pace with other top universities.</li>
                <li>Donated carry to fund scholarships and financial aid awards.</li>
                <li>
                  Inclusion in Base10’s on-campus STEM recruiting program, placing underrepresented
                  minority students in top tech internships and full-time roles.
                </li>
              </ul>
            </div>
            <div className="col white-bg">
              <div className="info-title">FOR GROWTH STAGE COMPANIES</div>
              <ul className="list">
                <li>
                  Exclusive access to the largest on-campus STEM recruiting program for URM talent.
                </li>
                <li>
                  Diversify your cap table with the largest collective commitment of HBCUs in
                  history.
                </li>
                <li>
                  24/7 access to our team of experts for all things DEI, including branding and
                  recruiting your next board member or executive leader from our portfolio of
                  underrepresented operators.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="cta">
        <div className="container">
          <div className="cta-content">
            <div className="row">
              <div className="col">
                <h1
                  data-sal="slide-right"
                  data-sal-delay="100"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  Want to join the Advancement Initiative?
                </h1>
                <a href="mailto:advance@base10.vc">
                  <button
                    style={{ height: '48px', fontSize: '20px', padding: '8px 16px' }}
                    data-sal="slide-right"
                    data-sal-delay="400"
                    data-sal-duration="700"
                    data-sal-easing="easeInOutQuad"
                    type="button"
                    className="w-200 btn btn-lg btn-primary"
                  >
                    Get in touch
                  </button>
                </a>
              </div>
              <div className="col hide-mobile"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="back">
        <div className="container">
          <a className="link-footer" href="/advancement-initiative/">
            Back to the Advancement Initiative Home Page
          </a>
        </div>
      </section>
    </div>
  );
};

export { ForeignSegmentPortfolio };
