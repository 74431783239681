import styled from 'styled-components';
import Img from 'gatsby-image';
import IndexLayout from '../../layouts';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { AppTitle, NavBar, TextCycler } from '../../components/ui';
import { BottomNavSegment, Footer, OurESGSegment } from '../../components/PageSegments';
import { ForeignSegment2 } from '../../components/PageSegments/foreign-page/ForeignComponent';
import { Link } from 'gatsby';
import favicon from '../../content/img/icon/favicon.ico';
import { ForeignSegmentPortfolio } from '../../components/PageSegments/foreign-page/PortfolioCompanies';

export default () => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Base10 Advancement Initiative | Companies</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <meta
          name="description"
          content="The Advancement Initiative invests in leading tech companies who are committed to helping generate billions of dollars in financial aid for HBCU students."
        />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/advancement-initiative/companies/" />
        <meta charSet="UTF-8" />
        <meta
          name="og:image"
          content="https://res.cloudinary.com/base10/image/upload/v1621888654/Header_Base10_V2_1_pmfsrc.jpg"
        />

        <meta name="og:url" content="https://base10.vc/advancement-initiative/" />
        <meta name="og:title" content="Base10 Advancement Initiative | Companies" />

        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta
          name="og:description"
          content="The Advancement Initiative invests in leading tech companies who are committed to helping generate billions of dollars in financial aid for HBCU students."
        />
        <meta
          name="description"
          content="The Advancement Initiative invests in leading tech companies who are committed to helping generate billions of dollars in financial aid for HBCU students."
        />
        <meta name="twitter:title" content="Base10 Advancement Initiative  | Companies" />
        <meta
          name="twitter:description"
          content="The Advancement Initiative invests in leading tech companies who are committed to helping generate billions of dollars in financial aid for HBCU students."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/base10/image/upload/v1621888654/Header_Base10_V2_1_pmfsrc.jpg"
        />
        <meta name="twitter:image:alt" content="Base10 Advancement Initiative | Companies" />
        <meta name="twitter:site" content="@Base10Partners" />
        <meta name="twitter:creator" content="@Base10Partners" />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="title"
          property="og:title"
          content="Base10 Advancement Initiative | Companies"
        />
        <meta
          name="image"
          property="og:image"
          content="https://res.cloudinary.com/base10/image/upload/v1621888654/Header_Base10_V2_1_pmfsrc.jpg"
        />
      </Helmet>
      <WhiteDiv />
      <NavBar invert />
      <ForeignSegmentPortfolio />
      <Main>
        <Footer email="mailto:advance@base10.vc" />
        <BottomNavSegment />
      </Main>
    </IndexLayout>
  );
};

const WhiteDiv = styled.div`
  height: 120px;
  width: 100%;
  background: #f4f8fb;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 200px; */
  /* height: 2000px; */
  /* background: grey; */
`;
